'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.contacts.factory:ContactDataManager

 # @description

###
angular
  .module 'mundoAdmin.contacts'
  .factory 'ContactDataManager', [
    'MundoDefaultManager'
    (MundoDefaultManager) ->
      ContactDataManagerBase = new MundoDefaultManager()


      ContactDataManagerBase.getTypeList = ->
        types = []
        @transport.all('contacts/data/types').customGET("").then((data) ->
          for k,v of data.results
            types.push({type:v})
          types
        )
        types

      ContactDataManagerBase.new = (contactId, data) ->
        #format correctly
        if data.type == 'BirthDate'
          data.value = moment(data.value).format('YYYY-MM-DD')
        if data.type == 'DeathDate'
          data.value = moment(data.value).format('YYYY-MM-DD')

        @transport.one('contacts', contactId).all("data/#{data.type}").post(_.pick(data, @getObject(data.type)))

      ContactDataManagerBase.removeData = (contactId, dataId) ->
        @transport.one('contacts', contactId).one('data', dataId).remove()

  #   defaultType
      defaultForm =
        [
          key: 'value'
          name: 'value'
          type: 'input'
          templateOptions:
            label: 'Value'
            placeholder: 'value'
            required: true
        ]

      ContactDataManagerBase.setExtraForm('default', defaultForm)
      ContactDataManagerBase.setObject('default', ['value'])
  #   type selector
      dataTypeForm =
        [
          key: 'dataType'
          name: 'dataType'
          type: 'select'
          templateOptions:
            label: 'Data type'
            placeholder: 'Data type'
            required: true
            labelProp: "type"
            valueProp: "type"
            options: ContactDataManagerBase.getTypeList()
        ]

      ContactDataManagerBase.setExtraForm('dataType', dataTypeForm)

  #   Date
      dataDateForm =
        [
          key: 'value'
          name: 'value'
          type: 'datepicker'
          templateOptions:
            label: 'Date'
            placeholder: ''
            required: true
        ]

      ContactDataManagerBase.setExtraForm('BirthDate', dataDateForm)
      ContactDataManagerBase.setExtraForm('DeathDate', dataDateForm)

  #   defaultType
      PoliceEmployee =
        [
          key: 'p44Number'
          name: 'p44Number'
          type: 'input'
          templateOptions:
            label: 'Value'
            placeholder: 'value'
            required: true
        ]

      ContactDataManagerBase.setExtraForm('PoliceEmployee', PoliceEmployee)
      ContactDataManagerBase.setObject('PoliceEmployee', ['p44Number'])

  #   type selector
      dataGenderForm =
        [
          key: 'value'
          name: 'value'
          type: 'select'
          templateOptions:
            label: 'Gender'
            required: true
            labelProp: "label"
            valueProp: "value"
            options: [
              {
                value: 'M'
                label: 'Male'
              },
              {
                value: 'F'
                label: 'Female'
              }
            ]
        ]

      ContactDataManagerBase.setExtraForm('Gender', dataGenderForm)
      ContactDataManagerBase
  ]
